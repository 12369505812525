<template>
  <div>
    <div class="center">
      <h2 class="heading-title">
        Your order was successfully placed!
      </h2>
    </div>

    <div class="order-details center" style="width:50%;float:left">
      <i class="fa fa-check-circle" aria-hidden="true" />

      <p>Thank You for Your Purchase</p>
      <p>Your Order id is :<span>#{{ orderId }}</span></p>
      <p>Click to view your purchased course</p>

      <v-btn
        color="lighten-2"
        dark
        @click="$router.push({path:'/Dashboard#yourcourse'})"
      >
        View your Course
      </v-btn>
    </div>
    <div style="width:50%;float:right">
      <h3 class="pd-b-20">
        Payment Details :
      </h3>
      <table cellpadding="5"
             width="100%"
             cellspacing="0"
             align="left"
             border="1"
      >
        <thead>
          <tr>
            <th>Product</th>
            <th>Price</th>
            <th>Qty</th>
            <th>Discount(%)</th>
            <th>Subtotal</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ productName }}</td>
            <td><span class="dollar">$</span>{{ price }}</td>
            <td>1</td>
            <td>{{ discount }}</td>
            <td><span class="dollar">$</span>{{ subtotal }}</td>
          </tr>
        </tbody>
      </table>

      <div class="sub-total">
        <p>Sub Total : <span class="dollar">$</span><span>{{ subtotal }}</span></p>
        <p>Discount : <span class="dollar">$</span><span>{{ discount }}</span></p>
        <p>Grand Total : <span class="dollar">$</span><span>{{ grandtotal }}</span></p>
      </div>
    </div>
  </div>
</template>
<script>
//import { StripeCheckout } from '@vue-stripe/vue-stripe';
//import { loadStripe } from "@stripe/stripe-js"
import axios from 'axios';

export default {
  components: {
    //StripeCheckout,
  },
  data () {
    // this.publishableKey = process.env.STRIPE_PUBLISHABLE_KEY;
    //this.publishableKey = "pk_test_51I1WobHFYyuKCP2O1E6kaWhag0JN5ohdp7MQp2agvibi1eDhJlzixfPoE7e7eNKXjKwtHcl0tt8TbTHev6yLw4MU008QY7Z4tT";
    return {
      price:null,
      title:null,
      discount:null,
      subtotal: null,
      loading: false,
      orderId: null,
      productName: null,
      price: null,
      discount: null,
      subtotal: null,
      grandtotal: null,
      lineItems: [
        {
          price: 'price_1IIdChHFYyuKCP2OMYSRurJb', // The id of the recurring price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      mode: 'payment',
      successURL: 'http://localhost:8080/success',
      cancelURL: 'http://localhost:8080/cancel',
    };
  },
  async mounted(){
    if(this.$route.query.session_id !== "undefined"){
        const response = await axios.get(process.env.VUE_APP_API+'user/'+localStorage.getItem('userId')+'/subscription/'+this.$route.query.session_id+'/success', { headers: {Authorization: 'Bearer ' + localStorage.getItem('token')}});
        if(response.data.success){
          this.orderId = response.data.data.subscription.subscription_id
          this.productName = response.data.data.session_line_items[0].course.title
          this.price = response.data.data.session_line_items[0].course.actual_price
          this.discount = response.data.data.session_line_items[0].course.discount
          this.subtotal = response.data.data.session_line_items[0].course.sale_price
          this.grandtotal = response.data.data.session.amount_total
        }
    }
  },
  async created (){
      // Store mutations
      await this.$store.dispatch('navigation/getData');
      this.$store.commit('navigation/userDashboard', false);
      this.$store.commit('navigation/titleStatus', false);
      this.$store.commit('navigation/replaceProton', true);
      this.$store.commit('navigation/changeTitle', 'Success');
    },
  methods: {
    submit () {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
      // this.$refs.checkoutRef.redirectToCheckout({sessionId: 'cs_KmeIFgWSfN5GW6tP2e5IQ0Vb9EA0q3…kW7DwsM6dAbhQ30' })
    },
  }
};
</script>

<style>

i.fa.fa-check-circle {
    color: green;
    font-size: 90px;
}

.v-content__wrap {
    margin: 100px;
	}

.sub-total p {
    margin-bottom: 5px;
}
.order-details {
margin-bottom: 5px;
}

th, td, tr, table {
border: 1px solid #a9a9a9;
text-align: center;

}
.pd-b-20 {
    padding-bottom: 20px;
}
h2.heading-title {
    margin-bottom: 20px;
}

.center {
    text-align: center;
}

button.v-btn.theme--dark.lighten-2 {
    height: 40px;
    font-size: 15px;
}
span.dollar {
        padding-right: 10px;
    }
</style>
